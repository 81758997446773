import React from 'react';
import LandingTemplate from '~/templates/LandingV2';
import { Translation } from '~/components/Translation';

const Landing = () => (
	<LandingTemplate
		heading={<Translation tid="landing.title" />}
		subHeading={<Translation tid="landing.subtitle.v2" />}
	/>
);

export default Landing;
