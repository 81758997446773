import { useEffect } from 'react';

type Action = 'lead' | 'purchase' | 'registration' | 'signup' | 'content';
const useIHeartTracking = ({ action }: { action: Action }) => {
	useEffect(() => {
		const pixel = document.createElement('img');
		const params = new URLSearchParams();
		params.append('ad_log', 'referer');
		if (action) {
			params.append('action', action);
		}
		params.append('pixid', 'dfd0fbd9-e7bd-4f9b-83ca-25f7bee979d8');
		pixel.src = `https://arttrk.com/pixel/?${params.toString()}`;
		pixel.width = 1;
		pixel.height = 1;
		pixel.style.position = 'fixed';
		pixel.alt = '';

		document.body.appendChild(pixel);

		return () => {
			document.body.removeChild(pixel);
		};
	}, [action]);

	return null;
};

export default useIHeartTracking;
